/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react'
import SEO from '../components/seo'
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'
import { ImitationsBlockStyle, ButtonStyle } from '../utils/copperTypography'
import { skyBlue, skyBlue10, oceanBlue30, oceanBlue, copper } from '../constants/theme'

import DrewSale from '../components/DrewSale'
import DrewSticker from '../components/drewSticker'

import hero from '../images/drew/headerHero.jpg'
import heroMobile from '../images/drew/headerHero-mobile.jpg'
import field from '../images/drew/field.jpg'
import drewCircle from '../images/drew/drew-circle.jpg'
import Icon1 from '../images/icons/copper-icon-copper-content.png';
import Icon2 from '../images/icons/copper-icon-compression.png';
import Icon4 from '../images/icons/copper-icon-moisture-wicking.png';
import drewSticker2 from '../images/drew/sticker2.jpg';
import drewSticker3 from '../images/drew/sticker3.jpg';
import vidStill from '../images/drew/still.jpg';

export const ImageBlockStyle = styled.div`

  background-color: ${skyBlue10};

  .grid {
    padding-top: 350px;
    background-image: url(${heroMobile});
    background-position: left top;
    background-size: 100% auto;

    @media (min-width: ${breakpoints.s}px){
      background-position: top center;
      background-size: cover;
      background-image: url(${hero});
      padding: 120px 30px;
    }

    @media (min-width: ${breakpoints.m}px){
      padding: 120px 30px;
    }

    @media (min-width: ${breakpoints.l}px){
      padding: 120px 30px;
    }

    background-repeat: no-repeat;
    max-width: 1320px;
    margin: auto;
  }

  .caption {

    padding: 20px;
    max-width: 100%;
    margin-left: auto;

    .icon {

      width: 40px;

      @media (min-width: ${breakpoints.s}px){
        width: 75px;
      }
    }

    @media (min-width: ${breakpoints.s}px){
      max-width: 200px;
      margin-left: 0;
    }


    @media (min-width: ${breakpoints.m}px){
      padding: 30px;
      max-width: 260px;
    }

    @media (min-width: ${breakpoints.l}px){
      padding: 30px;
      max-width: 300px;
    }
    color: white;
    background-color: ${oceanBlue};

    h2 {
      font-family: 'HKNova-Regular';
      letter-spacing: 0.10rem;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 15px;

      @media (min-width: ${breakpoints.l}px){
        font-size: 21px;
        line-height: 24px;
      }

    }

    h1 {

      font-size: 32px;

      @media (min-width: ${breakpoints.l}px){
        font-size: 46px;
      }

      font-family: 'gilroyExtraBold';
      letter-spacing: 0.1rem;
      font-weight: normal;
      margin-top: 0;
      line-height: 1.12;
      margin-bottom: 10px;
      color: ${copper};
    }

    p {

      font-size: 12px;

      @media (min-width: ${breakpoints.l}px){
        font-size: 14px;
      }


      font-family: 'HKNova-Medium';
      span {
        color: ${copper};
      }
    }

  }
`;


const ImageBlock = () => (
  <ImageBlockStyle>
    <div className='grid'>
      <div className='caption'>
        <img className='icon' src={ Icon1 } alt='banner' />
        <h2>
          Copper Compression is proud to welcome Drew to the team!
        </h2>
        <h1>
          Drew Brees<br />
        </h1>
        <p>
          "We need a better way to heal and that is why I chose to partner with Copper Compression."
        </p>
      </div>
    </div>
  </ImageBlockStyle>
)

const DrewBreesBlock = () => (
  <ImitationsBlockStyle>
    <div className='grid-contain'>
      <div className='grid'>
        <div className='block'>
          <img width="75" src={ Icon1 } alt='banner' />
          <h2>Copper Infused</h2>
          <p>Copper is woven into our fabric not sprayed so it doesn’t just wash away.</p>
        </div>
        <div className='block'>
          <img width="75" src={ Icon2 } alt='banner' />
          <h2>Compression</h2>
          <p>Adds stability & support to your body part when engaging in various activities.</p>
        </div>
        <div className='block'>
          <img width="75" src={ Icon4 } alt='banner' />
          <h2>Moisture Wicking</h2>
          <p>Material wicks sweat & dries really fast. Adds stability to your body part when engaging in various activities</p>
        </div>
      </div>
    </div>
  </ImitationsBlockStyle>
);


const HomeVideo = () => {

  return <div style={ { maxWidth: '1380px', margin: 'auto' } }>
    <video poster={ vidStill } style={ { width: '100%', marginTop: '24px' } } controls>
      <source src="https://www.coppercompression.com/videos/brees_overview_with_captions.mp4" type="video/mp4" />
    </video>
  </div>

}


export const BreesCornerContain = styled.div`

  .study-contain-item {
    background-color: ${skyBlue10};
    padding: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .item {
          padding-right: 30px;
    }

    div {
      img {
          width: 320px;
          border-radius: 0;
      }
    }

    h1 {
          margin-bottom: 15px;
      font-size: 32px;
    }

    p {
          font-size: 14px;
      max-width: 100%;
      width: 100%;
    }

    h3 {
          font-size: 10px;
    }
  }
`;

// const VideoBlock = styled.div`

//   padding: 20px 0;

//   background-color: ${ copper };

//   .yt-contain {
//     max-width: 1320px;
//     width: calc(100% - 40px);
//     margin: auto;
//   }

//   .yt-wrap {
//     position: relative;
//     overflow: hidden;
//     padding-top: 56.25%;
//   }

//   .yt-frame {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border: 0;
//   }
// `

export const StatsGrid = styled.div`
  background-image: url(${field});
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);


  .quote-area {

    background-color: rgba(71,131,181,0.6);
    border-radius: 0 20px 0 20px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
    text-shadow: 1px 1px 1px ${oceanBlue30};
    padding: 30px 30px 30px 30px;
    margin: 0 40px 0 0;
    text-align: left;

    h2 {
      margin: 0;
      margin-top: 20px;
    }

    h3 {
      color:white;
      margin: 0;
      line-height: 2;
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      span {
          color: ${copper};
      }
    }
  }



`

export const StatsBlock = styled.div`

  background-color: rgba(71,131,181,0.6);

  padding: 20px 30px;

  border-radius: 20px;

  min-width: 220px;

  box-shadow: 5px 5px 10px rgba(0,0,0,0.3);



  h2 {
    text-align: left;
    margin-bottom: 40px;
    color: white;
    text-shadow: 1px 1px 1px ${oceanBlue30};
  }

  dl, dd, dt {
      margin: 0
  }

  dl {
      text-align: left;
  }

  dt {
      color: #fff;
      text-shadow: 1px 1px 1px ${oceanBlue30};
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 7px;
      margin-top: 20px;
      margin-left: 3px;
  }

  dd {
      font-size: 46px;
      font-family: 'gilroyExtraBold';
      letter-spacing: 0.1rem;
      font-weight: normal;
      margin-top: 0;
      line-height: 1.12;
      margin-bottom: 10px;
      color: ${copper};
      text-shadow: 1px 1px 1px ${oceanBlue30};
  }

`

export const BlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  max-width: 1320px;
  padding: 20px;
  margin: auto;


  &.brees-title {

    padding: 30px 0 20px 0;
    margin-bottom: 0;

    p {
      font-size: 36px;
      font-family: 'gilroyLight';
      max-width: 820px;
      line-height: 1.3;
      margin: auto;
      padding: 0 20px;

      @media (max-width: ${breakpoints.s}px){
            font-size: 26px;
      }

    }


  }


  &.text-center {
          text-align: center;
  }

  p {
    font-size: 16px;
    font-family: 'HKNova-Regular';
    max-width: 600px;
    line-height: 1.4;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 60px;
    padding: 0;
  }

  h3 {
          letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;
    margin-left: 3px;
    line-height: 2;

    span {
          font-size: 18px;
    }
  }

  h2 {
          font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
          border-radius: 100%;
  }

  h1 {
          font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;

    @media (max-width: ${breakpoints.s}px){
          font-size: 32px;
    }
  }

  /* a {
    text-decoration: none;
    color: ${skyBlue};
    font-size: 14px;
    font-family: 'HKNova-Bold';
    letter-spacing: 0.10rem;
    line-height: 1.4;
  } */


`;

export const DrewOpen = styled.div`

  @media (min-width: ${breakpoints.l}px){
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 40px;
  }

  @media (min-width: ${breakpoints.l}px){
    h3 {
      margin-top: 0;
    }
  }

  p {
    padding: 0;
  }

  a {
  
    margin-top: 30px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

  }

  align-items: center;
  margin-top: 40px;

  div.img-contain {

    display: none;

    @media (min-width: ${breakpoints.m}px){
      display: block;
    }

    margin-right: 20px;
    img {
      max-width: 420px;
    }
  }

  div.text-contain {
    text-align: left;
    margin-left: 20px;
    p {
          margin: 0;
    }
  }

`


const DrewBreesPage = () => (
  <>
    <SEO title="Drew Brees Join Copper Compression" description="Copper Compression is excited to announce our multi-year partnership with Pro Quarterback, Drew Brees."/>
    
    <ImageBlock />

    <BlockStyle className="text-center brees-title">
      <div className="text-contain">
        <p>"Throughout my time as a pro football player I've taken some hits and tried everything to help with muscle pain and recovery and then I found Copper Compression. When I wear the products, I don't feel injured, I feel enhanced."</p>
      </div>
    </BlockStyle>

    <HomeVideo />

    <BlockStyle className="text-center">
      <DrewOpen>
        <div className="img-contain">
          <img src={ drewCircle } alt="Drew Brees - Strength to Heal" />
        </div>
        <div className="text-contain">
          <h3>Welcome to the team</h3>
          <h1>We're excited to announce our multi-year partnership with Pro Quarterback, Drew Brees.</h1>
          <p>In an effort to heighten our mission to make protective performance and personal care solutions available to everyone, Drew will work with us to educate new audiences on the connection between healing the human body with proven, powerful targeted compression.</p>
          <br />
          <p>“With Copper Compression, I'm able to reduce recovery time between practices and game day and the targeted compression helps me get the most out of my performance both on and off the field,” Drew tells us. “It doesn’t matter if you're a professional athlete, or weekend warrior or somebody who goes to work every day - our bodies never take a day off and Copper Compression is for anybody doing anything.”</p>
          <ButtonStyle to='/blog/copper-compression-signs-long-term-partnership-with-champion-and-mvp-quarterback-drew-brees'>Read More</ButtonStyle>
        </div>
      </DrewOpen>
    </BlockStyle>

    <DrewSale />


    <DrewSticker img={ drewSticker3 } bg={ oceanBlue } caption="“With Copper Compression, I’m able to reduce recovery time between practices and game day.”" hideName />

    <BlockStyle className="text-center">
      <h2>Feel the difference</h2>
      <p className='wide'>Functionality is one of the defining principles behind every Copper Compression product. Our diverse offering not only provides support and protection, but allows you to move on with life.</p>
      <DrewBreesBlock />
    </BlockStyle>

    <DrewSticker reverse img={ drewSticker2 } bg={ skyBlue } caption="“After all of the years of wearing compression products, I find that Copper Compression is absolutely the best!”" hideName />

    {/* 
    <BlockStyle className="text-center">
      <StatsGrid>
        <div className="quote-area">
          <h3>The Strength to Stay on the Field</h3>
        </div>
        <StatsBlock>
          <h2>Drew Career Stats</h2>
          <dl>
            <dt>Games Played</dt>
            <dd>200</dd>
            <dt>Season MVP</dt>
            <dd>Championship</dd>
            <dt>Passing Yards</dt>
            <dd>79,612</dd>
            <dt>Touchdowns</dt>
            <dd>565</dd>
            <dt>Passes Thrown</dt>
            <dd>10,459</dd>
            <dt>Completion Rate</dt>
            <dd>67.8%</dd>
            <dt>Playoff Touchdowns</dt>
            <dd>34</dd>
          </dl>
        </StatsBlock>
      </StatsGrid>
    </BlockStyle> */}




  </>
);

export default DrewBreesPage;
